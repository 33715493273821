@keyframes spin
	0%
		opacity .2
		transform rotate(0deg)

	50%
		opacity 1
		transform rotate(359deg)

	100%
		opacity .2
		transform rotate(718deg)

.star
	position absolute
	width 58px
	height 45px
	background-image url('../images/star/star.png')
	background-position center
	background-size cover
	background-repeat no-repeat
	opacity 0
	transform-origin center
	animation spin 8s linear infinite
	pointer-events none

	@media (max-width $grid-md)
		width (58 / 2) px
		height (45 / 2) px

	&-small
		transform scale(.7)
