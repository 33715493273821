.panel
	padding 6rem
	border-radius 2rem
	background linear-gradient(154.04deg, #4765FF 2.07%, rgba(69, 28, 156, .35) 83.63%)
	backdrop-filter blur(20rem)

	@media (max-width $grid-lg)
		padding 4rem 2rem

	&__image
		width 10rem
		height 10rem

		@media (max-width $grid-md)
			width 5rem
			height 5rem

	&__item
		display flex
		align-items center
		margin-bottom 2rem
		font-size 1.8rem
		gap 1rem

		@media (max-width $grid-md)
			align-items center
			margin-bottom 1rem
			font-size 1.2rem

		&:nth-last-child(0)
			margin-bottom 0

	&__accent
		font-size 2.4rem

		@media (max-width $grid-md)
			font-size 1.6rem
