$black ?= #000000
$white ?= #ffffff
$grid-xs = 320px
$grid-sm = 575px
$grid-md = 767px
$grid-lg = 959px
$grid-xl = 1199px
$grid-xxl = 1440px

html,
body
	overflow-x hidden
	-webkit-font-smoothing antialiased
	text-rendering optimizeLegibility !important

html
	font-size 10px

body
	font-size 1.6rem

a,
button
	transition .5s all ease
