.card
	display flex
	flex-direction row
	align-items center
	padding 2rem
	height 19.5rem
	border-radius 1rem
	background linear-gradient(180deg, #4765FF 0%, #451C9C 100%)
	box-shadow 0px 0px 4.5rem rgba(25, 17, 84, .69)

	@media (max-width $grid-xxl)
		padding-left 0

	@media (max-width $grid-xl)
		padding-left 2rem

	&__image
		width 10rem
		height 10rem

	&__title
		font-weight 800
		font-size 3.2rem
		line-height 1

	&__text
		font-weight 600
		font-size 2rem
		line-height 1.4
