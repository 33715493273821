.cloud
	position absolute
	top -500px
	left -100%
	z-index -1
	display block
	width 1591px
	height 868px
	background-image url('../images/cloud/cloud.png')
	background-position center
	background-size contain
	background-repeat no-repeat
	transform rotate(15deg)

	@media (max-width $grid-lg)
		left -160%
