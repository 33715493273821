.lamp
	position absolute
	top 0
	right 0
	transform translate(39%, 0)

	@media (max-width $grid-xxl)
		transform translate(36%, 15%) scale(.7)

	@media (max-width $grid-xl)
		transform translate(38%, 35%) scale(.55)
