.offer
	&__equinty
		display flex
		flex-direction row
		align-items center
		gap 3rem

		@media (max-width $grid-md)
			gap 0.5rem

	&__text
		font-weight 800
		font-size 3.5rem
		line-height 1.2

		@media (max-width $grid-xl)
			font-size 2.6rem

		@media (max-width $grid-lg)
			font-size 1.6rem

		@media (max-width $grid-md)
			font-size 1.4rem

	&__plus
		position relative
		flex-shrink 0
		width 5.8rem
		height 5.8rem

		@media (max-width $grid-md)
			width 3rem
			height 3rem

		&:before
			position absolute
			top 0
			bottom 0
			left 50%
			width 3px
			background-color #f9bc37
			content ''
			transform translateX(-50%)

		&:after
			position absolute
			top 50%
			right 0
			left 0
			height 3px
			background-color #f9bc37
			content ''
			transform translatey(-50%)

	&__price
		display flex
		flex-direction column
		align-items flex-end

		@media (max-width $grid-lg)
			align-items flex-start
			padding-top 2rem

		&_strike
			position relative
			font-weight 600
			font-size 5rem
			line-height 1.22

			@media (max-width $grid-xl)
				font-size 3rem

			@media (max-width $grid-md)
				font-size 2rem

			&:after
				position absolute
				top 50%
				right 0
				left 0
				height 4px
				background-color #e90a6b
				content ''
				transform translateY(-50%)

		&_actual
			color #e90a6b
			font-weight 800
			font-size 8rem
			line-height 1.22
			transform translateY(-1.5rem)

			@media (max-width $grid-xl)
				font-size 6rem
				transform translateY(-1rem)

			@media (max-width $grid-md)
				font-size 4rem
				transform translateY(-.5rem)
