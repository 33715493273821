.title
	margin-bottom 0
	padding-bottom 0

	&-h2
		font-weight 800
		font-size 6.4rem
		line-height 1.22

		@media (max-width $grid-xl)
			font-size 5rem

	&-h3
		font-weight 800
		font-size 6rem
		line-height 1.22

		@media (max-width $grid-xl)
			font-size 5rem

		@media (max-width $grid-md)
			font-size 2.4rem

	&-h4
		font-weight 800
		font-size 5rem
		line-height 1.22

		@media (max-width $grid-xl)
			font-size 3.6rem

		@media (max-width $grid-md)
			font-size 2.4rem

	&__image
		position relative
		padding-top: ((184 / 657) * 100)%

		&:before
			position absolute
			top 50%
			left 50%
			display block
			padding-top: ((327 / 885) * 100)%
			width 134%
			background-image url('../images/title/title.png')
			background-position center
			background-size cover
			content ''
			transform translate(-50%, -67%)
