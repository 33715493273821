.pace
	position fixed
	top 0
	left 0
	z-index 1100
	width 100%
	transition transform .5s ease-out
	transform translate3d(0, -50px, 0)
	pointer-events none
	user-select none
	-webkit-pointer-events none
	-webkit-user-select none
	-moz-user-select none
	-webkit-transform translate3d(0, -50px, 0)
	-ms-transform translate3d(0, -50px, 0)
	-webkit-transition transform .5s ease-out
	-ms-transition transform .5s ease-out

.pace.pace-active
	transform translate3d(0, 0, 0)
	-webkit-transform translate3d(0, 0, 0)
	-ms-transform translate3d(0, 0, 0)

.pace .pace-progress
	position fixed
	top 0
	right 100%
	z-index 2000
	display block
	width 100%
	height 10px
	background #dd0a3d
	pointer-events none

.pace-running
	&:after
		position fixed
		top 0
		right 0
		bottom 0
		left 0
		z-index 1000
		background-color #0e0944
		content ''
		opacity .95
