.form
	&-control
		padding 1.8rem 2rem
		width 100%
		border 0
		border-radius 1rem
		background-color var(--bs-white)
		font-weight 400
		font-size 1.6rem

		&:focus
			outline none

	&-check
		&-input
			display none

			&:checked + .form-check-label
				&:before
					background-image url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNSAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjUgMUw1LjMwNzY5IDlMMSA0LjkyNTkzIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+Cjwvc3ZnPgo=')
					background-position center
					background-repeat no-repeat

		&-label
			display flex
			align-items flex-start
			color #ddd
			text-decoration underline
			font-size 1.6rem
			line-height 1.2
			gap 2rem

			&:before
				display block
				flex-shrink 0
				width 2rem
				height 2rem
				border 1px solid var(--bs-white)
				content ''
