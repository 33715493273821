.section
	position relative
	overflow hidden

	& > .container
		position relative
		z-index 1

	&-index
		background-image url('../images/section/section-index.jpg')
		background-position center
		background-size cover
		background-repeat no-repeat

		& > .container
			@media (max-width $grid-lg)
				padding-bottom 510px

			@media (max-width $grid-md)
				padding-bottom 440px

			@media (max-width $grid-sm)
				padding-bottom 330px

	&-main
		background-image url('../images/section/section-main.jpg')
		background-position center
		background-size cover
		background-repeat no-repeat

	&-ok
		min-height 100vh
		background-image url('../images/section/section-main.jpg')
		background-position center
		background-size cover
		background-repeat no-repeat
