.ok
	padding 12rem
	border-radius 2rem
	background linear-gradient(154.04deg, #4765FF 2.07%, rgba(69, 28, 156, .35) 83.63%)
	text-align center
	backdrop-filter blur(20rem)

	@media (max-width $grid-xl)
		padding 8rem

	@media (max-width $grid-lg)
		padding 6rem 2rem
		text-align left

	&__title
		font-weight 600
		font-size 5rem
		line-height 1.22

		@media (max-width $grid-xl)
			font-size 4rem

		@media (max-width $grid-lg)
			font-size 2.6rem

	&__close
		position absolute
		top 4rem
		right 4rem
		width 2rem
		height 2rem
		background-image url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjIyIiB2aWV3Qm94PSIwIDAgMjIgMjIiIHdpZHRoPSIyMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjEgMS0yMCAyMG0wLTIwIDIwIDIwIiBzdHJva2U9IiMxZTI0NWEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
		background-position center
		background-size cover
		background-repeat no-repeat

		@media (max-width $grid-lg)
			top 2rem
			right 2rem
			width 1.6rem
			height 1.6rem

		&:hover
			opacity .8

	&__text
		font-size 2rem

		@media (max-width $grid-xl)
			font-size 1.6rem

		@media (max-width $grid-lg)
			font-size 1.2rem

	&__button
		@media (max-width $grid-lg)
			display block
			width 100%
