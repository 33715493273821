.footer
	background-image url('../images/footer/footer.jpg')
	background-position bottom right
	background-size cover
	background-repeat no-repeat

	&__text
		color #d6d6d6
		font-size 1.4rem
		line-height 1.2

		@media (max-width $grid-sm)
			font-size .8rem

	&__link
		color #d6d6d6
		text-decoration none
		font-size 1.4rem
		line-height 1.2

		@media (max-width $grid-sm)
			font-size .8rem
