.btn
	display inline-block
	padding 2rem 2.4rem
	border 0
	border-radius 1.4rem
	text-align center
	text-decoration none
	font-weight 600
	font-size 2.6rem

	@media (max-width $grid-md)
		padding 1.8rem
		font-size 2rem

	&-primary
		background linear-gradient(176.85deg, rgba(242, 13, 82, .81) -7.94%, #DD0A3D 60.79%)
		color var(--bs-white)

		&:hover
			color var(--bs-white)
			transform translateY(-3px)

	&-secondary
		padding 1.2rem 7.6rem
		border-radius .6rem
		background-color #4456E7
		color var(--bs-white)
		font-weight 500
		font-size 2rem

		&:hover
			background-color darken(#4456E7, 10%)
			color var(--bs-white)

	&-block
		display block
		width 100%
