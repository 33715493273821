.index
	&__18
		text-align right
		font-weight 500
		font-size 4.2rem

		@media (max-width $grid-xl)
			font-size 3rem

		@media (max-width $grid-md)
			font-size 2rem

	&__curtains
		position absolute
		top 0
		right 0
		bottom 0
		width 534px
		background-image url('../images/index/index-curtains.png')
		background-position center
		background-size cover
		background-repeat no-repeat

		@media (max-width $grid-lg)
			display none

	&__girl
		position absolute
		right 0
		bottom 0
		z-index 0
		z-index -1
		width 1615px
		height 1172px
		background-image url('../images/index/index-girl.png')
		background-position center
		background-size cover
		background-repeat no-repeat
		transform translate(27%, 0) scale(.9)
		transform-origin center bottom

		@media (max-width $grid-xxl)
			transform translate(21%, 0) scale(.8)

		@media (max-width $grid-xl)
			transform translate(31%, 0) scale(.55)

		@media (max-width $grid-lg)
			right auto
			left 50%
			transform translate(-50%, 0) scale(.5)

		@media (max-width $grid-md)
			transform translate(-50%, 0) scale(.4)

		@media (max-width $grid-sm)
			transform translate(-51%, 0) scale(.3)

		@media (max-width $grid-xs)
			transform translate(-51%, 0) scale(.3)

	&__text
		font-weight 600
		font-size 2.4rem
		line-height 1.2

		@media (max-width $grid-xl)
			font-size 2rem

	&__satelite
		position absolute
		top 52%
		right 13%
		z-index 1

	&__car
		position absolute
		top 42%
		right 20%
		z-index 1

	&__ultra
		position absolute
		top 26%
		right 55%
		z-index 1

		@media (max-width $grid-xxl)
			top 18%

	&__receiver
		position absolute
		top 65%
		right 26%
		z-index 1
